*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 17.5px;
}

.App {
}

html {
  height: 100%;
}

body,
body > div,
body > div > div {
  box-sizing: border-box;
  height: 100%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeText {
  animation-name: fadein;
  animation-duration: 0.5s;
}
